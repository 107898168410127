import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import testimonial1 from "../assets/testimonial/01.png";
import testimonial2 from "../assets/testimonial/02.png";
import testimonial3 from "../assets/testimonial/03.png";
import testimonial4 from "../assets/testimonial/04.png";

export const Testimonials = () => {
  return (
    <section
      id="feedbacks"
      className="testimonial mt-[80px] xl:mt-[200px] relative z-20"
    >
      <div className="testimonial__bg container mx-auto bg-accent-secondary rounded-[70px] px-6">
        <div className="flex flex-col items-center pt-[88px] pb-[110px]">
          <h2 className="testimonial__title h2 mb-9 text-center">
            What our clients say
          </h2>
          {/* slider  */}
          <div className="w-full">
            <div className="testimonial__slider swiper h-[400px]">
              <Swiper
                className="h-full"
                pagination={{
                  Bullets: true,
                  clickable: true,
                }}
                loop={true}
                slidesPerView={3}
                spaceBetween={20}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  960: {
                    slidesPerView: 2,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                }}
                modules={[Pagination]}
              >
                {/* slide 1  */}
                <SwiperSlide className="swiper-slide flex flex-col mx-auto">
                  {/* item  */}
                  <div className="testimonial__item w-full max-w-[450px] h-[340px] bg-white rounded-[30px] flex flex-col justify-center p-9 mx-auto">
                    <div className="flex mx-auto">Coming Soon...</div>
                    {/* <div className="flex gap-4 mb-6 mx-auto">
                      <img src={testimonial1} alt="" />
                      <div>
                        <h3 className="h3">Nattasha Mith</h3>
                        <div>Greenville, USA</div>
                      </div>
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the typesetting
                      industry. Ipsum has been.
                    </p> */}
                  </div>
                </SwiperSlide>

                {/* slide 2  */}
                <SwiperSlide className="swiper-slide flex flex-col mx-auto">
                  {/* item  */}
                  <div className="testimonial__item w-full max-w-[450px] h-[340px] bg-white rounded-[30px] flex flex-col justify-center p-9 mx-auto">
                    <div className="flex mx-auto">Coming Soon...</div>
                    {/* <div className="flex gap-4 mb-6 mx-auto">
                      <img src={testimonial2} alt="" />
                      <div>
                        <h3 className="h3">Jessica White</h3>
                        <div>Oak Ridge, USA</div>
                      </div>
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the typesetting
                      industry. Ipsum has been.
                    </p> */}
                  </div>
                </SwiperSlide>

                {/* slider 3 */}
                <SwiperSlide className="swiper-slide flex flex-col mx-auto">
                  {/* item  */}
                  <div className="testimonial__item w-full max-w-[450px] h-[340px] bg-white rounded-[30px] flex flex-col justify-center p-9 mx-auto">
                    <div className="flex mx-auto">Coming Soon...</div>
                    {/* <div className="flex gap-4 mb-6 mx-auto">
                      <img src={testimonial3} alt="" />
                      <div>
                        <h3 className="h3">Mike Davis</h3>
                        <div>Berlin, Germany</div>
                      </div>
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the typesetting
                      industry. Ipsum has been.
                    </p> */}
                  </div>
                </SwiperSlide>

                {/* slide 4  */}
                <SwiperSlide className="swiper-slide flex flex-col mx-auto">
                  {/* item  */}
                  <div className="testimonial__item w-full max-w-[450px] h-[340px] bg-white rounded-[30px] flex flex-col justify-center p-9 mx-auto">
                    <div className="flex mx-auto">Coming Soon...</div>
                    {/* <div className="flex gap-4 mb-6 mx-auto">
                      <img src={testimonial4} alt="" />
                      <div>
                        <h3 className="h3">Olivia Wilson</h3>
                        <div>Blue Ridge, France</div>
                      </div>
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the typesetting
                      industry. Ipsum has been.
                    </p> */}
                  </div>
                </SwiperSlide>
              </Swiper>
              {/* swiper pagination  */}
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
