import React, { useState } from "react";
import { ContactText } from "../Resources/datos";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    interests: {
      workwithus: false,
      quote: false,
      services: false,
      talktous: false,
      other: false,
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        interests: {
          ...formData.interests,
          [name]: checked,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail(e);
    console.log(e.target);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wsswvpi", // Replace with your EmailJS service ID
        "template_mlq4n6c", // Replace with your EmailJS template ID
        e.target,
        "za6kw6mkKjnLv7nrP" // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!");

          // Reiniciar los campos del formulario
          setFormData({
            name: "",
            email: "",
            message: "",
            interests: {
              workwithus: false,
              quote: false,
              services: false,
              talktous: false,
              other: false,
            },
          });
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send the message.");
        }
      );
  };
  return (
    <div className="relative z-20 w-full mt-[10%]    pb-10  ">
      <div className="lg:w-[80%] w-full  bg-hero-gradient2 mx-auto h-auto rounded-lg drop-shadow-2xl max-w-[1200px] px-16 py-24 flex contact__form flex-col lg:flex-row">
        <div className="lg:w-[50%] w-full flex flex-col justify-between py-6 text-center lg:text-left">
          <div className="text-3xl lg:text-4xl tracking-[8px] font-bold contact__title leading-normal">
            <h1 className="">Les’t talk </h1>
            <h1>
              on something{" "}
              <span className=" text-transparent bg-clip-text bg-hero-gradient">
                great
              </span>
            </h1>{" "}
            <h1>together</h1>
          </div>

          <div className="flex flex-col lg:gap-12 gap-4 mt-10 lg:mt-0 -ml-10 text-lg lg:ml-0  lg:text-xl ">
            {ContactText.contactinfo.map((item, index) => {
              return (
                <div className="flex gap-4 text-text contact__content">
                  <div className="text-subtitle">{item.icon}</div>{" "}
                  {item.content}
                </div>
              );
            })}
          </div>
          <div>
            {/* socials  */}
            <ul className="footer__item text-black flex gap-6 justify-center xl:justify-start mt-10 lg:mt-4 ">
              {ContactText.socialmedias.map((item, index) => {
                return (
                  <li>
                    <a href={item.url} className=" ">
                      <div className="contact__content hover:scale-125 transition-all p-2 rounded-full ">
                        {item.icon}
                      </div>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="xl:w-[50%]  w-[130%] lg:ml-0 -ml-12 mx-auto p-6 space-y-6 bg-white rounded-lg shadow-lg border-2 border-gray-200 contact__form"
        >
          {/* Interests */}
          <div className="space-y-2">
            <h2 className="text-lg font-semibold text-gray-700">
              I’m interested in:
            </h2>
            <div className="flex flex-wrap gap-3">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="workwithus"
                  checked={formData.interests.workwithus}
                  onChange={handleChange}
                  className="hidden"
                />
                <span
                  className={`py-2 px-4 rounded-full border  ${
                    formData.interests.workwithus
                      ? "text-transparent bg-clip-text bg-hero-gradient "
                      : "border-gray-300"
                  }`}
                >
                  Work With Us
                </span>
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="quote"
                  checked={formData.interests.quote}
                  onChange={handleChange}
                  className="hidden"
                />
                <span
                  className={`py-2 px-4 rounded-full border ${
                    formData.interests.quote
                      ? "text-transparent bg-clip-text bg-hero-gradient"
                      : "border-gray-300"
                  }`}
                >
                  Get a Quote
                </span>
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="services"
                  checked={formData.interests.services}
                  onChange={handleChange}
                  className="hidden"
                />
                <span
                  className={`py-2 px-4 rounded-full border ${
                    formData.interests.services
                      ? "text-transparent bg-clip-text bg-hero-gradient"
                      : "border-gray-300"
                  }`}
                >
                  Adquire Our Service
                </span>
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="talktous"
                  checked={formData.interests.talktous}
                  onChange={handleChange}
                  className="hidden"
                />
                <span
                  className={`py-2 px-4 rounded-full border  ${
                    formData.interests.talktous
                      ? "text-transparent bg-clip-text bg-hero-gradient"
                      : "border-gray-300"
                  }`}
                >
                  Talk to Us
                </span>
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="other"
                  checked={formData.interests.other}
                  onChange={handleChange}
                  className="hidden"
                />
                <span
                  className={`py-2 px-4 rounded-full border ${
                    formData.interests.other
                      ? "text-transparent bg-clip-text bg-hero-gradient"
                      : "border-gray-300"
                  }`}
                >
                  Other
                </span>
              </label>
            </div>
          </div>

          {/* Name */}
          <div>
            <label className="block text-md font-medium text-gray-700">
              Your name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full mt-1 p-3 border-b-2 border-color-primary outline-none focus:border-color-title transition"
              placeholder="Jhon Smith"
            />
          </div>

          {/* Email */}
          <div>
            <label className="block text-md font-medium text-gray-700">
              Your email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full mt-1 p-3 border-b-2 border-color-primary outline-none focus:border-color-title transition"
              placeholder="email@gmail.com"
            />
          </div>

          {/* Message */}
          <div>
            <label className="block text-md font-medium text-gray-700">
              Your message
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full mt-1 p-3 border-b-2 border-color-primary outline-none focus:border-color-title transition resize-none"
              rows="4"
              placeholder="Type your message here"
            ></textarea>
          </div>

          {/* Submit Button */}
          <div className="text-center">
            <button
              type="submit"
              className="bg-hero-gradient w-full text-white py-2 px-6 rounded-full hover:from-[#87CEEB] hover:to-[#ADD8E6] transition"
            >
              Send message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
