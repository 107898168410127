import React from "react";

export const Steps = () => {
  return (
    <section className="steps mt-[80px] xl:mt-[200px] relative z-20">
      <div className="container mx-auto">
        {/* grid items  */}
        <div className="grid grid-cols-1 gap-12 xl:grid-cols-3">
          {/* step 1  */}
          <div className="steps__step text-center">
            <div className="mb-4">
              <i className="ri-compasses-2-line text-5xl text-accent "></i>
            </div>
            <h3 className="h3 mb-5">Customized Cleaning Plans</h3>
            <p className="mb-5 max-w-md mx-auto">
              We create tailored cleaning schedules based on your business's
              unique requirements, ensuring every area is covered, from guest
              rooms to public spaces
            </p>
            <a href="#" className="font-medium flex justify-center gap-2">
              Read More <i className="ri-arrow-right-line text-accent"></i>
            </a>
          </div>

          {/* step 2  */}
          <div className="steps__step text-center">
            <div className="mb-4">
              <i className="ri-magic-line text-5xl text-accent "></i>
            </div>
            <h3 className="h3 mb-5">Eco-Friendly Products</h3>
            <p className="mb-5 max-w-md mx-auto">
              We use sustainable and eco-friendly cleaning products that are
              safe for both your guests and the environment, maintaining a
              healthy and welcoming atmosphere
            </p>
            <a href="#" className="font-medium flex justify-center gap-2">
              Read More <i className="ri-arrow-right-line text-accent"></i>
            </a>
          </div>

          {/* step 3  */}
          <div className="steps__step text-center">
            <div className="mb-4">
              <i className="ri-tools-line text-5xl text-accent "></i>
            </div>
            <h3 className="h3 mb-5">Professional Cleaning Teams</h3>
            <p className="mb-5 max-w-md mx-auto">
              Our highly trained cleaning teams are experts in delivering
              top-notch services, ensuring that your hotel or business meets the
              highest cleanliness standards every day
            </p>
            <a href="#" className="font-medium flex justify-center gap-2">
              Read More <i className="ri-arrow-right-line text-accent"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
