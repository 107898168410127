import React from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "./Components/Landing";
import ContactForm from "./Components/ContactForm";
import { Header } from "./Components/Header";

const RoutePage = () => {
  return (
    <div>
      <div className="container mx-auto">
        <Header />
      </div>

      <main className="max-w-[1920px] mx-auto bg-white overflow-hidden">
        <div className="xl:bg-grid xl:bg-center xl:bg-repeat-y fixed top-0 bottom-0 left-0 right-0 z-10"></div>
        <Routes>
          <Route index path="/" Component={Landing} />
          <Route path="/home" Component={Landing} />
          <Route path="/contact" Component={ContactForm} />
        </Routes>
      </main>
    </div>
  );
};

export default RoutePage;
