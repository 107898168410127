// import icons
import { BsTelephone } from "react-icons/bs";
import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa6";
import { FiMail } from "react-icons/fi";
import { FaFacebookF } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";

export const navegacion = [
  {
    name: "Home",
    href: "/#",
  },
  {
    name: "About",
    href: "/#aboutus",
  },
  {
    name: "Services",
    href: "/#services",
  },
  {
    name: "Feedbacks",
    href: "/#feedbacks",
  },
  {
    name: "Contact",
    href: "/#contact",
  },
];

export const ContactText = {
  title: "Are You Ready To Work With Us Now?",
  description: "Get a Free Quote",
  image: "Vector",
  imageMobile: "VectorMobile",
  contactinfo: [
    {
      icon: <FiMail />,
      content: "Heydi.Herrera@herlcleaningservices.com",
    },
    {
      icon: <BsTelephone />,
      content: "+1 504 351 0744",
    },
    {
      icon: <IoLocationOutline />,
      content: "571 Rosa Av. Suit 205 Metairie, LA. 70005",
    },
  ],
  socialmedias: [
    {
      icon: <FaFacebookF />,
      url: "/home",
    },
    {
      icon: <FaTwitter />,
      url: "/about",
    },
    {
      icon: <FaInstagram />,
      url: "/services",
    },
    {
      icon: <FaLinkedin />,
      url: "/feedbacks",
    },
  ],
};
