import React from "react";
import work1 from "../assets/work/w1.png";
import work2 from "../assets/work/w2.png";
import work3 from "../assets/work/w3.png";
import work4 from "../assets/work/w4.png";

export const Work = () => {
  return (
    <section
      id="services"
      className="services mt-[80px] xl:mt-[150px] relative z-20"
    >
      <div className="container mx-auto xl:px-0">
        {/* text  */}
        <div className="text-center mb-24">
          <h2 className="work__title h2 mb-4">Services We Offer</h2>
          <p className="work__subtitle max-w-3xl mx-auto">
            It is a long established fact that a reader will be distracted by
            the of readable content of page lookings at its layouts points.
          </p>
        </div>
        {/* grid  */}
        <div className="work__grid grid grid-cols-1 xl:grid-cols-2 gap-x-[104px] gap-y-[56px]">
          {/* item  */}
          <div className="w-full max-w-[548px] h-full mx-auto">
            <img className="mb-6" src={work1} alt="" />
            <div className="flex justify-between items-center w-full">
              <div className="space-y-2">
                <h3 className="h3">Commercial Office Cleaning</h3>
                <p className="text-justify">
                  Keep your workspace professional with our office cleaning
                  services. From nightly cleanups to specialized treatments for
                  office equipment, we maintain a hygienic and appealing office
                  environment, boosting productivity and morale.
                </p>
              </div>
            </div>
          </div>

          {/* item  */}
          <div className="w-full max-w-[548px] h-full mx-auto">
            <img className="mb-6" src={work2} alt="" />
            <div className="flex justify-between items-center w-full">
              <div className="space-y-2">
                <h3 className="h3">Post-Construction Cleaning</h3>
                <p className="text-justify">
                  After the hammers fall silent, we step in. Our
                  post-construction clean-up includes meticulous dusting, debris
                  removal, and a thorough clean to ensure your project ends on a
                  clean note, ready for its next phase or occupants.
                </p>
              </div>
            </div>
          </div>

          {/* item  */}
          <div className="w-full max-w-[548px] h-full mx-auto">
            <img className="mb-6" src={work3} alt="" />
            <div className="flex justify-between items-center w-full">
              <div className="space-y-2">
                <h3 className="h3">Short-Term Rental Cleaning</h3>
                <p className="text-justify">
                  For properties that are always on the go, we provide quick
                  turnaround cleaning services. Our team focuses on high-touch
                  areas, sanitizes, refreshes linens, and ensures every guest
                  enjoys a spotless environment, enhancing guest satisfaction
                  and your property's reputation.
                </p>
              </div>
            </div>
          </div>

          {/* item  */}
          <div className="w-full max-w-[548px] h-full mx-auto">
            <img className="mb-6" src={work4} alt="" />
            <div className="flex justify-between items-center w-full">
              <div className="space-y-2">
                <h3 className="h3">Move-In/Move-Out Cleaning</h3>
                <p className="text-justify">
                  Transform your property to an impeccable state, whether for
                  your new tenants or to impress incoming residents. Our service
                  includes comprehensive cleaning of all areas, ensuring every
                  corner is spotless, followed by minor touch-ups to keep your
                  property looking its best.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
