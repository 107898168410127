import React, { useState } from "react";
import { navegacion } from "../Resources/datos";

const NavMobile = ({ language }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <nav>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer text-4xl text-heading ml-[10px] lg:hidden"
      >
        <i className="ri-menu-4-line text-4xl text-white"></i>
      </div>
      <ul
        className={`${
          isOpen ? "max-h-60 p-8" : "max-h-0 p-0"
        } flex flex-col absolute w-full top-24 left-0 bg-white rounded-md shadow-primary space-y-6 overflow-hidden transition-all`}
      >
        {navegacion.map((item, index) => {
          return (
            <li key={index}>
              <a href={item.href}>{item.name}</a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default NavMobile;
