import React, { useEffect, useState } from "react";

// import logo
import logo from "../assets/logo3.svg";

// import components
import Nav from "./Nav";
import NavMobile from "./NavMobile";
import { Link } from "react-router-dom";

export const Header = () => {
  const [header, setHeader] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 36 ? setHeader(true) : setHeader(false);
    });
  }, [header]);

  return (
    <header
      className={`${
        header ? "top-0 h-[80px] z-30" : "top-9 z-30"
      } fixed bg-primary w-full max-w-[90vw] lg:max-w-[1170px] mx-auto rounded-2xl h-[75px] shadow-primary px-4 lg:px-8 z-20 transition-all duration-500 flex items-center justify-between `}
    >
      {/* logo */}
      <a href="/#">
        <img src={logo} alt="" className="" />
      </a>
      {/* nav */}
      <div>
        <div className="hidden lg:flex ">
          <Nav />
          <Link to="/contact">
            <a className=" items-center align-middle flex ml-6 font-medium text-[18px] mt-[5px] text-accent hover:text-accent-hover hover:cursor-pointer transition-all ">
              Work With Us
            </a>
          </Link>
        </div>
        <div className="flex items-center">
          {/* nav mobile */}
          <NavMobile />
        </div>
      </div>
    </header>
  );
};
