import React from "react";

export const Stats = () => {
  return (
    <section className="stats mt-[80px] xl:mt-[150px] relative z-20 bg-accent-secondary py-[80px] xl:py-[150px]">
      <div className="container mx-auto">
        {/* grid  */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-12 text-center mx-auto justify-center">
          {/* grid item  */}
          <div className="stats__item text-center xl:border-r xl:border-accent">
            <h3 className="h1 font-primary text-accent">7</h3>
            <p>Years of Experience</p>
          </div>

          {/* grid item  */}
          <div className="stats__item text-center xl:border-r xl:border-accent ">
            <h3 className="h1 font-primary text-accent">3</h3>
            <p>States we Service</p>
          </div>

          {/* grid item  */}
          <div className="stats__item text-center ">
            <h3 className="h1 font-primary text-accent">84000+</h3>
            <p>Total Cleanings</p>
          </div>
        </div>
      </div>
    </section>
  );
};
