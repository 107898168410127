import React from "react";

export const Contact = () => {
  return (
    <section
      id="contact"
      className="contact mt-[80px] xl:mt-[150px] relative z-20"
    >
      <div className="cntact__container container mx-auto bg-primary sm:rounded-[70px] py-[80px]">
        <div className="contact__text max-w-[640px] mx-auto text-center">
          <h2 className="h2 text-white mb-4">Do you want to join HERL?</h2>
          <p className="text-white mb-8 max-w-sm  xl:max-w-none mx-auto ">
            It's well-known that distractions are inevitable, but together we
            stay focused on delivering quality.
          </p>
          <a href="/contact">
            <button className="btn btn-accent mx-auto">
              Connect with us <i className="ri-arrow-right-line text-primary" />
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};
