import React from "react";
import logo from "../assets/logo3.svg";

export const Footer = () => {
  return (
    <footer className="footer mt-[80px] xl:mt-[150px] relative z-20">
      <div className="container mx-auto px-0">
        <div className="flex flex-col xl:flex-row xl:gap-[100px] xl:mb-[150px] ">
          <div className="footer__item w-full max-w-[400px] mx-auto mb-8 text-center xl:text-left ">
            {/* logo  */}
            <a
              className="flex justify-center xl:justify-start mb-8"
              href="#home"
            >
              <img src={logo} alt="" />
            </a>
            <p className="mb-8 text-xl ">
              Readers often get distracted while browsing. Stay connected
              through our socials.
            </p>
            {/* socials  */}
            <ul className="text-primary flex gap-[54px] justify-center xl:justify-start ">
              <li>
                <a href="#home">
                  <i className="ri-facebook-fill"></i>
                </a>
              </li>
              <li>
                <a href="#home">
                  <i className="ri-twitter-fill"></i>
                </a>
              </li>
              <li>
                <a href="#home">
                  <i className="ri-linkedin-fill"></i>
                </a>
              </li>
              <li>
                <a href="#home">
                  <i className="ri-instagram-fill"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className=" flex flex-col xl:flex-row text-center xl:text-left gap-12 xl:gap-[100px] xl:justify-end">
            <div className="footer__item">
              <h3 className="h3 mb-3">Pages</h3>
              <ul className="flex flex-col gap-4">
                <li>
                  <a href="#home">About Us</a>
                </li>
                <li>
                  <a href="#home">Testimonials</a>
                </li>
                <li>
                  <a href="#home">Our Work</a>
                </li>
                <li>
                  <a href="#home">News</a>
                </li>
                <li>
                  <a href="#home">Contact</a>
                </li>
              </ul>
            </div>

            <div className="footer__item">
              <h3 className="h3 mb-3">Services</h3>
              <ul className="flex flex-col gap-4">
                <li>
                  <a href="#home">Kitchen</a>
                </li>
                <li>
                  <a href="#home">Living Area</a>
                </li>
                <li>
                  <a href="#home">Bathroom</a>
                </li>
                <li>
                  <a href="#home">Bedroom</a>
                </li>
              </ul>
            </div>

            <div className="footer__item max-w-[260px] mx-auto xl:mx-0">
              <h3 className="h3 mb-3">Contact</h3>
              <div className="flex flex-col gap-6 text-[20px]">
                <p>671 Rosa Ave Suite 205 Metairie LA 70005</p>
                <p>heydi.herrera@herlcleaningservices.com</p>
                <p>Louisiana - Tennessee - Texas</p>
                <p>+1 (504)-351-0744</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* copyright  */}
      <p className="footer__copyright text-center text-lg py-10 bg-white xl:border-t">
        Copyright &copy; Herl Cleanings 2024. All Rights reserved.
      </p>
    </footer>
  );
};
