import React from "react";
import { Link } from "react-router-dom";

export const Hero = () => {
  return (
    <section className=" hero h-[940px] bg-hero  xl:rounded-bl-[290px] relative z-20 bg-cover">
      <div className="container mx-auto h-full flex items-center justify-center xl:justify-start">
        {/*  text */}
        <div className="hero__text w-[567px] flex flex-col items-center text-center xl:text-left lg:items-start  ">
          <h1 className="h1 mb-8">Spotless Stays for Memorable Experiences</h1>
          <p className="mb-8">
            Transforming every hotel into a pristine sanctuary, we elevate guest
            satisfaction with our meticulous cleaning services.
          </p>
          <Link to="/contact">
            <button className="btn btn-primary mx-auto xl:mx-0">
              Get free estimation
              <i className="ri-arrow-right-line text-accent"></i>
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};
