import React from "react";
import about from "../assets/about/img.png";
import { FaCheck } from "react-icons/fa6";

export const About = () => {
  return (
    <section
      id="aboutus"
      className="aboutus about mt-[80px] xl:mt-[200px] bg-accent-secondary py-24 relative z-20"
    >
      <div className="container mx-auto xl:px-0">
        <div className="flex flex-col xl:flex-row text-center xl:text-left justify-between items-center gap-8 xl:gap-[74px]">
          {/* text  */}
          <div className="about__text flex-1 order-2 xl:order-none max-w-xl xl:max-w-[440px] flex flex-col items-center xl:items-start gap-8">
            <h2 className="h2">Why Choose Us?</h2>
            <div className="flex gap-6">
              <div className="min-w-[35px] h-[35px] rounded-full bg-accent/40 flex items-center text-lg justify-center text-color-title">
                <FaCheck />
              </div>
              <div>
                <h1 className="text-left"> Eco-Friendly Solutions:</h1>
                <p className="">
                  We use environmentally friendly products for a cleaner planet.
                </p>
              </div>
            </div>
            <div className="flex gap-6">
              <div className="min-w-[35px] h-[35px] rounded-full bg-accent/40 flex items-center text-lg justify-center text-color-title">
                <FaCheck />
              </div>
              <div>
                <h1 className="text-left">Attention to Detail:</h1>
                <p className="">
                  Every service is performed with precision and care.
                </p>
              </div>
            </div>
            <div className="flex gap-6">
              <div className="min-w-[35px] h-[35px] rounded-full bg-accent/40 flex items-center text-lg justify-center text-color-title">
                <FaCheck />
              </div>
              <div>
                <h1 className="text-left"> Customizable Services:</h1>
                <p className="">
                  ailored cleaning solutions to fit your unique needs.
                </p>
              </div>
            </div>
            <div className="flex gap-6">
              <div className="min-w-[35px] h-[35px] rounded-full bg-accent/40 flex items-center text-lg justify-center text-color-title">
                <FaCheck />
              </div>
              <div>
                <h1 className="text-left"> Reliability</h1>
                <p className="">
                  With flexible scheduling and dependable service, we're here
                  when you need us.
                </p>
              </div>
            </div>
          </div>
          {/* img  */}
          <div className="about__img order-1 xl:order-none max-w-[453px] mx-auto xl:max-w-none xl:mx-0">
            <img src={about} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};
