import React from "react";
import { Hero } from "./Hero";
import { Stats } from "./Stats";
import { Steps } from "./Steps";
import { About } from "./About";
import { Work } from "./Work";
import { Testimonials } from "./Testimonials";
import { Contact } from "./Contact";
import { Footer } from "./Footer";
// import { Brands } from "./Brands";

const Landing = () => {
  return (
    <div>
      <Hero />
      <Steps />
      <About />
      <Work />
      <Testimonials />
      {/* <Brands /> */}
      <Stats />
      <Contact />
      <Footer />
    </div>
  );
};

export default Landing;
