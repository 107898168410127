import "./App.css";
import { Header } from "./Components/Header";
import { Hero } from "./Components/Hero";
import { Steps } from "./Components/Steps";
import { About } from "./Components/About";
import { Testimonials } from "./Components/Testimonials";
import { Brands } from "./Components/Brands";
import { Work } from "./Components/Work";
import { Stats } from "./Components/Stats";
import { News } from "./Components/News";
import { Contact } from "./Components/Contact";
import { Footer } from "./Components/Footer";
import ScrollReveal from "scrollreveal";
import { useEffect } from "react";
import RoutePage from "./RoutePage";

function App() {
  useEffect(() => {
    // scroll reveal animations
    const sr = ScrollReveal({
      origin: "bottom",
      distance: "60px",
      duration: 3000,
      delay: 400,
      //reset: true
    });

    //hero
    sr.reveal(".hero__text", { origin: "top" });

    // steps
    sr.reveal(".steps__step", { distance: "100px", interval: 100 });

    // about
    sr.reveal(".about__text", { origin: "left" });
    sr.reveal(".about__img", { origin: "right", delay: 500 });

    // testimonial
    sr.reveal(".testimonial__bg", { delay: 500 });
    sr.reveal(".testimonial__title");
    sr.reveal(".testimonial__slider", { delay: 700 });

    // brands
    sr.reveal(".brands__img", { delay: 400, distance: "100px", interval: 100 });

    // work
    sr.reveal(".work__title");
    sr.reveal(".work__subtitle", { delay: 500 });
    sr.reveal(".work__grid", { delay: 700 });

    // stats
    sr.reveal(".stats");
    sr.reveal(".stats__item", {
      distance: "100px",
      interval: 100,
    });

    // news
    sr.reveal(".news__title");
    sr.reveal(".news__subtitle", { delay: 500 });
    sr.reveal(".news__item", {
      distance: "100px",
      interval: 100,
      delay: 1000,
    });

    // contact
    sr.reveal(".contact__container");
    // contact
    sr.reveal(".contact__text", { origin: "left", delay: 200 });
    sr.reveal(".contact__form", {
      origin: "left",
      distance: "20px",
      duration: 1000,
      delay: 200,
      opacity: 0,
      easing: "ease-in-out",
      interval: 100,
    });
    sr.reveal(".contact__title", {
      origin: "left",
      distance: "20px",
      duration: 1000,
      delay: 200,
      opacity: 0,
      easing: "ease-in-out",
    });
    sr.reveal(".contact__content", {
      origin: "bottom",
      distance: "20px",
      duration: 1000,
      delay: 200,
      opacity: 0,
      easing: "ease-in-out",
      interval: 100,
    });

    // footer
    sr.reveal(".footer__item", {
      distance: "100px",
      interval: 100,
    });
    sr.reveal(".footer__copyright");
  }, []); // Se ejecutará solo una vez al montar el componente

  return (
    <div className="App bg-gray-100 relative">
      <RoutePage />
    </div>
  );
}

export default App;
