import React, { useState } from 'react';
import { useEffect } from 'react';
//import { navigation } from '../Resources/data'
import { navegacion } from '../Resources/datos'

const Nav = ({ language }) => {

    const [languageState, setLanguage] = useState(language);

    useEffect(() => {
        setLanguage(language)
    }, [language])
    return (
        <nav className=''>
            <ul className='fixed w-full h-0 p-0 overflow-hidden border-t text-text-primary top-[90px] left-0 right-0 flex flex-col gap-4 lg:relative lg:flex-row lg:p-0 lg:top-0 lg:border-none lg:h-full transition-all duration-300 gap-x-[26px]'>

                {navegacion.map((item, index) => {
                    return (
                        <li key={index}>
                            <a href={item.href}>{item.name}</a>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default Nav;
